.ModalInput {
    min-width: 200px;
    max-width: 300px;
}

.modal-bright-button {
    background-color: rgb(255, 215, 80);
    color: #000;
    font-weight: bold;
}

.delete-data {
    margin-left: auto;
}

.delete-data > button {
    color: #000 !important;
    font-weight: bold !important;
    margin-left: auto;
}

.snapshotItemHeader {
    background-color: #383434;
}

.snapshotItem {
    background-color: rgb(65, 65, 65);
}

.snapcolor-primary {
    background-color: #242424;
}

.snapcolor-secondary {
    background-color: rgb(44, 44, 44);
}

.SnapshotError {
    width: 100%;
    color: red;
    border-radius: 20px;
    border: 2px solid red;
    font-size: medium;
    margin: 20px 0;
    padding: 5px;
}

.ReactModal__Overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 20000;
    display: block;
}

/* Apparently z-index is broken for Examine.css, this is workaround: */

.ReactModalPortal {
    z-index: 20000;
}

.ReactModal__Overlay--after-open {
    display: inline !important;
}

.modal-main {
    color: #fff;
    position: fixed;
    width: 60%;
    max-width: 600px;
    min-width: 510px;
    height: auto;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 20000;
    padding: 20px;
    border-radius: 20px;
    border: 2px solid rgb(255, 215, 80);
    background-image: url(../images/div-background.png);
}

.modal-main textarea {
    width: 100%;
    height: 90px;
    max-width: 520px;
    max-height: 350px;
}

.modal-main-button {
    margin: 5px;
}

/* this button acts like a link: */

.modal-main-button {
    background: none!important;
    border: none;
    padding: 0!important;
    color: rgb(255, 215, 80);
    text-decoration: none;
    cursor: pointer;
}

.modal-main-button:hover {
    background: none!important;
    border: none;
    padding: 0!important;
    color: #2FD5E7;
    text-decoration: underline;
    cursor: pointer;
}

.modal-main-button:active {
    background: none!important;
    border: none;
    padding: 0!important;
    color: #7C6E39;
    text-decoration: underline;
    cursor: pointer;
}

.top-close-button {
    position: absolute;
    right: 0;
    top: 0;
}

.reportIssueInput {
    background-color: #fff;
}

.userName {
    width: auto;
    min-width: 10px;
}

.userControls {
    display: flex;
    align-content: center;
    justify-content: space-between;
}

.userControls > button {
}

@media (min-width: 1px) {
    .modal-main {
        margin-left: -100px;
        zoom: 0.4;
        /* IE */
        -moz-transform: scale(0.4);
        /* Firefox */
        -moz-transform-origin: 0 0;
        -o-transform: scale(0.4);
        /* Opera */
        -o-transform-origin: 0 0;
        -webkit-transform: scale(0.4);
        /* Safari And Chrome */
        -webkit-transform-origin: 0 0;
        transform: scale(0.4);
        /* Standard Property */
        transform-origin: 0 0;
        /* Standard Property */
    }
}

@media (min-width: 226px) {
    .modal-main {
        margin-left: -144px;
        zoom: 0.56;
        /* IE */
        -moz-transform: scale(0.56);
        /* Firefox */
        -moz-transform-origin: 0 0;
        -o-transform: scale(0.56);
        /* Opera */
        -o-transform-origin: 0 0;
        -webkit-transform: scale(0.56);
        /* Safari And Chrome */
        -webkit-transform-origin: 0 0;
        transform: scale(0.56);
        /* Standard Property */
        transform-origin: 0 0;
        /* Standard Property */
    }
}

@media (min-width: 326px) {
    .modal-main {
        margin-left: -180px;
        zoom: 0.6;
        /* IE */
        -moz-transform: scale(0.60);
        /* Firefox */
        -moz-transform-origin: 0 0;
        -o-transform: scale(0.60);
        /* Opera */
        -o-transform-origin: 0 0;
        -webkit-transform: scale(0.60);
        /* Safari And Chrome */
        -webkit-transform-origin: 0 0;
        transform: scale(0.70);
        /* Standard Property */
        transform-origin: 0 0;
        /* Standard Property */
    }
}

@media (min-width: 436px) {
    .modal-main {
        margin-left: -220px;
        zoom: 0.86;
        /* IE */
        -moz-transform: scale(0.86);
        /* Firefox */
        -moz-transform-origin: 0 0;
        -o-transform: scale(0.86);
        /* Opera */
        -o-transform-origin: 0 0;
        -webkit-transform: scale(0.86);
        /* Safari And Chrome */
        -webkit-transform-origin: 0 0;
        transform: scale(0.86);
        /* Standard Property */
        transform-origin: 0 0;
        /* Standard Property */
    }
}

@media (min-width: 526px) {
    .modal-main {
        margin-left: -260px;
        zoom: 0;
        /* IE */
        -moz-transform: scale(1);
        /* Firefox */
        -moz-transform-origin: 0 0;
        -o-transform: scale(1);
        /* Opera */
        -o-transform-origin: 0 0;
        -webkit-transform: scale(1);
        /* Safari And Chrome */
        -webkit-transform-origin: 0 0;
        transform: scale(1);
        /* Standard Property */
        transform-origin: 0 0;
        /* Standard Property */
    }
}