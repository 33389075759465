.charSheet {
    margin: auto;
    font-size: 12px;
    width: 482px;
    height: 562px;
    background-image: url('../images/charsheet_background.png');
}

.charSheetWrapper {
    margin: auto;
}

.notFound, .loadingMessage, .errorMessage {
    margin: auto;
}

.charSheetMini {
    display: flex;
    flex-direction: column;
    margin: auto;
    padding-left: 25px;
}

.charSheetMiniInfo {
    font-size: medium;
    border-radius: 20px;
    padding: 10px;
    border: 2px solid rgb(255, 215, 80);
    background-image: url(../images/div-background.png);
}

.charSheetExtras {
    padding-top: 12px;
    padding-bottom: 12px;
    min-width: 470px;
    max-width: 720px;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    border: 2px solid rgb(255, 215, 80);
    margin: 20px auto 20px auto;
    padding: 10px;
    background-image: url(../images/div-background.png);
}

.charSheetCreateSnapshot {
    padding-bottom: 12px;
    display: flex;
}

.statusIcon {
    padding-right: 6px;
}

.charSheet>div {
    margin: 0;
    padding: 0;
    color: antiquewhite;
    word-wrap: break-word;
}

.charSheetTopTabs {
    display: block;
    width: 482px;
    height: 77px;
}

/* Tab buttons */

.charSheetTabButtons {
    display: flex;
    flex-direction: row;
    width: 482px;
    height: 32px;
    padding-left: 0;
    padding-top: 40px;
}

.charSheetTabButtonStats {
    padding: 0;
    margin-left: 7px;
    height: 32px;
    width: 60px;
    background-image: url('../images/tabs/stats-active.png');
}

.charSheetTabButtonStats:hover {
    padding: 0;
    margin-left: 7px;
    height: 32px;
    width: 60px;
    background-image: url('../images/tabs/stats-active-hover.png');
}

.charSheetTabButtonInactiveStats {
    padding: 0;
    margin-left: 7px;
    height: 32px;
    width: 60px;
    background-image: url('../images/tabs/stats-inactive.png');
}

.charSheetTabButtonInactiveStats:hover {
    padding: 0;
    margin-left: 7px;
    height: 32px;
    width: 60px;
    background-image: url('../images/tabs/stats-inactive-hover.png');
}

.charSheetTabButtonSkills {
    margin-left: 1px;
    height: 32px;
    width: 60px;
    background-image: url('../images/tabs/skills-active.png');
}

.charSheetTabButtonSkills:hover {
    margin-left: 1px;
    height: 32px;
    width: 60px;
    background-image: url('../images/tabs/skills-active-hover.png');
}

.charSheetTabButtonInactiveSkills {
    margin-left: 1px;
    height: 32px;
    width: 60px;
    background-image: url('../images/tabs/skills-inactive.png');
}

.charSheetTabButtonInactiveSkills:hover {
    margin-left: 1px;
    height: 32px;
    width: 60px;
    background-image: url('../images/tabs/skills-inactive-hover.png');
}

.charSheetTabButtonDisabledSkills {
    margin-left: 1px;
    height: 32px;
    width: 60px;
}

.charSheetTabButtonFeats {
    margin-left: 1px;
    height: 32px;
    width: 61px;
    background-image: url('../images/tabs/feats-active.png');
}

.charSheetTabButtonFeats:hover {
    margin-left: 1px;
    height: 32px;
    width: 61px;
    background-image: url('../images/tabs/feats-active-hover.png');
}

.charSheetTabButtonInactiveFeats {
    margin-left: 1px;
    height: 32px;
    width: 61px;
    background-image: url('../images/tabs/feats-inactive.png');
}

.charSheetTabButtonInactiveFeats:hover {
    margin-left: 1px;
    height: 32px;
    width: 61px;
    background-image: url('../images/tabs/feats-inactive-hover.png');
}

.charSheetTabButtonDisabledFeats {
    margin-left: 1px;
    height: 32px;
    width: 60px;
}

.charSheetMiddleBody {
    width: 482px;
    display: inline-flex;
    flex-direction: row;
    position: relative;
}

.charSheetLeftBar {
    display: inline-block;
    width: 15px;
}

.charSheetRightBar {
    display: inline-block;
    width: 36px;
}

.charSheetRightBarStatButtons {
    display: flex;
    width: 36px;
    height: 95px;
}

.charSheetStatButtonMain, .charSheetStatButtonDetails, .charSheetStatButtonBio, .charSheetStatButtonBioInactive, .charSheetStatButtonMain, .charSheetStatButtonMainInactive, .charSheetStatButtonDetails, .charSheetStatButtonDetailsInactive {
    height: 32px;
}

.charSheetStatButtonBio {
    background-image: url('../images/tabs/bio-active.png');
    background-position: 8px 0;
    background-repeat: no-repeat;
}

.charSheetStatButtonBioInactive {
    background-image: url('../images/tabs/bio-inactive.png');
    background-position: 8px 0;
    background-repeat: no-repeat;
}

.charSheetStatButtonBioInactive:hover {
    background-image: url('../images/tabs/bio-inactive-hover.png');
    background-position: 8px 0;
    background-repeat: no-repeat;
}

/* this is the "greyed out" version until we have data filled in the details tab. */

.charSheetStatButtonDetails {
    background-image: url('../images/tabs/details-inactive-hover.png');
    background-position: 8px 0;
    background-repeat: no-repeat;
}

/* this is the "greyed out" version until we have data filled in the details tab. */

.charSheetStatButtonDetailsInactive {
    background-image: url('../images/tabs/details-inactive-hover.png');
    background-position: 8px 0;
    background-repeat: no-repeat;
}

.charSheetStatButtonDetailsInactive:hover {
    background-image: url('../images/tabs/details-inactive-hover.png');
    background-position: 8px 0;
    background-repeat: no-repeat;
}

.charSheetStatButtonMain {
    background-image: url('../images/tabs/stats-subtab-active.png');
    background-position: 8px 0;
    background-repeat: no-repeat;
}

.charSheetStatButtonMainInactive {
    background-image: url('../images/tabs/stats-subtab-inactive.png');
    background-position: 8px 0;
    background-repeat: no-repeat;
}

#charSheetBodyContents {
    width: 431px;
    min-width: 431px;
    max-width: 431px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-image: url('../images/tabs/stats.png');
    top: 0;
    left: 15px;
    z-index: 0;
}

#charSheetBio {
    width: 431px;
    min-width: 431px;
    max-width: 431px;
    left: 15px;
    z-index: 5;
    background-image: url('../images/tabs/biography.png');
    height: 459px;
}

.charSheetBioText {
    margin-top: 45px;
    margin-left: 35px;
    width: 360px;
    height: 372px;
    overflow-x: auto;
}

#charSheetSkillsTab {
    width: 431px;
    min-width: 431px;
    max-width: 431px;
    z-index: 5;
    background-image: url('../images/tabs/skills.png');
    height: 473px;
}

.charSheetSkillsText {
    margin-top: 60px;
    margin-left: 4px;
    width: 427px;
    height: 386px;
    overflow-y: auto;
    -moz-box-shadow: inset 0 0 6px #170503;
    -webkit-box-shadow: inset 0 0 6px #170503;
    box-shadow: inset 0 0 6px #170503;
}

#charSheetFeatsTab {
    width: 431px;
    min-width: 431px;
    max-width: 431px;
    z-index: 5;
    background-image: url('../images/tabs/feats.png');
    height: 473px;
}

.charSheetFeatsText {
    margin-top: 40px;
    margin-left: 9px;
    width: 389px;
    height: 380px;
    overflow-y: auto;
}

#charSheetDetails {
    width: 431px;
    min-width: 431px;
    max-width: 431px;
    left: 15px;
    z-index: 5;
    background-image: url('../images/tabs/details.png');
    height: 459px;
}

/* Main Containers: */

.charSheetName {
    width: 431px;
    height: 33px;
    padding-left: 12px;
    font-size: 21px;
}

.charSheetClass {
    color: #eae294;
    display: flex;
    flex-direction: row;
    width: 431px;
    height: 25px;
    padding-left: 12px;
    font-weight: bold;
    font-size: 14px;
}

.charSheetRaceAlignmentGender {
    width: 431px;
    height: 35px;
    display: flex;
    flex-direction: row;
}

.charSheetXpActionPoints {
    width: 431px;
    height: 43px;
    display: flex;
    flex-direction: row;
}

.charSheetAbiltityStats {
    width: 109px;
    height: 220px;
    text-align: right;
    padding-top: 13px;
    padding-right: 13px;
    font-size: 17px;
    text-shadow: 0px 0px 1px #000000, 0px 0px 4px #000000;
    color: #E4E395;
}

.charSheetMods {
    width: 33px;
    height: 220px;
    padding-top: 16px;
    font-size: 14px;
    text-shadow: 0px 0px 1px #000000, 0px 0px 4px #000000;
}

.charSheetDerivedStats {
    width: 289px;
    padding-left: 111px;
}

.charSheetDamageReduction {
    padding-top: 8px;
    padding-left: 12px;
    width: 431px;
    height: 64px;
}

.charSheetResistances {
    padding-top: 20px;
    padding-left: 12px;
    width: 431px;
    display: flex;
    flex-direction: row;
}

/* Fields: */

/* charSheetRaceAlignmentGender */

.charSheetRace {
    width: 218px;
    height: 22px;
    padding-left: 12px;
}

.charSheetAlignment {
    width: 105px;
    height: 22px;
}

.charSheetGender {
    width: 108px;
    height: 22px;
}

/* charSheetXpActionPoints */

.charSheetXp {
    width: 330px;
    text-align: center;
    padding-left: 15px;
    padding-right: 61px;
    padding-top: 2px;
    z-index: 3;
}

.charSheetActionPoints {
    margin-top: -4px;
    font-size: 16px;
    width: 36px;
    text-align: center;
}

/* custom background image for xp bar: */

.charSheetXpBackground {
    margin-left: 1px;
    width: 252px;
    height: 14px;
    display: block;
    position: absolute;
    background-image: url('../images/xp_bar-noxp.png');
    z-index: 0;
}

.charSheetXpTextValue {
    font-size: 10px;
    font-weight: bold;
    width: 252px;
    height: 14px;
    display: block;
    position: absolute;
    margin-top: -1px;
    text-shadow: 0px 0px 1px #000000, 0px 0px 2px #000000, 0px 0px 3px #000000, 0px 0px 4px #000000;
    z-index: 1;
}

/* charSheetBaseStats */

.charSheetSTR {
    height: 34px;
}

.charSheetDEX {
    height: 34px;
}

.charSheetCON {
    height: 34px;
}

.charSheetINT {
    height: 34px;
}

.charSheetWIS {
    height: 34px;
}

.charSheetCHA {
    height: 34px;
}

/* charSheetMods */

.charSheetSTRMod {
    height: 34px;
}

.charSheetDEXMod {
    height: 34px;
}

.charSheetCONMod {
    height: 34px;
}

.charSheetINTMod {
    height: 34px;
}

.charSheetWISMod {
    height: 34px;
}

.charSheetCHAMod {
    height: 34px;
}

/* charSheetDerivedStats */

.charSheetHP {
    width: 98px;
    height: 20px;
    text-align: center;
}

.charSheetSpells {
    height: 18px;
    text-align: center;
    display: flex;
    flex-direction: row;
}

.charSheetKI {
    text-align: center;
    width: 98px;
    height: 20px;
}

.charSheetArmor {
    text-align: center;
    display: flex;
    flex-direction: row;
}

.charSheetSF {
    text-align: center;
    display: table-cell;
    vertical-align: bottom;
    width: 98px;
    height: 40px;
}

.charSheetReflex {
    text-align: center;
    width: 98px;
    height: 20px;
}

.charSheetWill {
    text-align: center;
    width: 98px;
}

.charSheetAttack {
    text-align: center;
    height: 24px;
    display: inline-flex;
    flex-direction: row;
    padding-top: 4px;
}

.charSheetSpellFortitude {
    text-align: center;
    width: 155px;
    height: 20px;
    padding-left: 64px;
}

.charSheetFortifictation {
    text-align: center;
    width: 155px;
    padding-left: 64px;
}

/* charSheetResistances */

.charSheetAcid {
    width: 60px;
    text-align: left;
    padding-left: 24px;
    padding-right: 2px;
}

.charSheetCold {
    width: 60px;
    text-align: left;
    padding-left: 24px;
    padding-right: 2px;
}

.charSheetElectric {
    width: 60px;
    text-align: left;
    padding-left: 24px;
    padding-right: 2px;
}

.charSheetFire {
    width: 60px;
    text-align: left;
    padding-left: 24px;
    padding-right: 2px;
}

.charSheetSonic {
    width: 60px;
    text-align: left;
    padding-left: 24px;
    padding-right: 2px;
}

.charSheetPhysical {
    width: 60px;
    text-align: left;
    padding-left: 24px;
    padding-right: 2px;
}

.charSheetMagical {
    width: 60px;
    text-align: left;
    padding-left: 24px;
    padding-right: 2px;
}

/* sub-fields: */

/* charSheetSpells */

.charSheetSP {
    width: 98px;
}

.charSheetSpellSource {
    width: 20px;
    text-align: center;
}

/* charSheetArmor */

.charSheetAC {
    width: 100px;
}

.charSheetBlock {
    width: 52px;
}

/* charSheetAttack */

.charSheetBAB {
    vertical-align: bottom;
    width: 100px;
}

.charSheetAttackBonus {
    vertical-align: bottom;
    width: 20px;
}

.charSheetWeaponPower {
    vertical-align: bottom;
    width: 20px;
}

/* skills */

.charSheetSkill {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    background-image: url('../images/tabs/skill-item-background.png');
    background-repeat: no-repeat;
}

.charSheetSkillIcon {
    height: 49px;
    padding: 0;
    margin: 0;
    padding-top: 6px;
}

.charSheetSkillName, .charSheetSkillKey, .charSheetSkillTotal, .charSheetSkillRank, .charSheetSkillAbilityMod, .charSheetSkillMiscMod {
    height: 49px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.charSheetSkillIcon {
    margin-left: 13px;
    width: 32px;
}

.charSheetSkillName {
    width: 110px;
    text-align: left;
    font-weight: bold;
    padding-left: 7px;
    justify-content: left;
    color: #BFBDAE;
}

.charSheetSkillKey {
    width: 40px;
    color: #BFBDAE;
    font-weight: bold;
}

.charSheetSkillTotal {
    width: 55px;
    color: #D6CF78;
    font-size: 16px;
}

.charSheetSkillRank {
    width: 55px;
    color: #ABA262;
}

.charSheetSkillAbilityMod {
    width: 50px;
    color: #ABA262;
}

.charSheetSkillMiscMod {
    width: 36px;
    color: #ABA262;
}

.charSheetPermalink {
    padding-top: 10px;
    margin-bottom: 0;
}

/* feats */

.collapser {
    display: inline;
}

.charSheetFeatSubCategory, .charSheetFeat {
    padding-left: 18px;
}

.charSheetFeatCategory {
    padding-left: 20px;
}

.charSheetFeatCategoryCollapsed {
    padding-left: 20px;
}

.collapseButton,
.expandButton {
    margin-left: -19px;
}


@media (min-width: 226px) {
    .charSheetMini, .charSheetWrapper {
        zoom: 0.6;
        /* IE */
        -moz-transform: scale(0.60);
        /* Firefox */
        -moz-transform-origin: 0 0;
        -o-transform: scale(0.60);
        /* Opera */
        -o-transform-origin: 0 0;
        -webkit-transform: scale(0.60);
        /* Safari And Chrome */
        -webkit-transform-origin: 0 0;
        transform: scale(0.60);
        /* Standard Property */
        transform-origin: 0 0;
        /* Standard Property */
    }
}

@media (min-width: 326px) {
    .charSheetMini, .charSheetWrapper {
        zoom: 0.7;
        /* IE */
        -moz-transform: scale(0.70);
        /* Firefox */
        -moz-transform-origin: 0 0;
        -o-transform: scale(0.70);
        /* Opera */
        -o-transform-origin: 0 0;
        -webkit-transform: scale(0.70);
        /* Safari And Chrome */
        -webkit-transform-origin: 0 0;
        transform: scale(0.70);
        /* Standard Property */
        transform-origin: 0 0;
        /* Standard Property */
    }
}

@media (min-width: 436px) {
    .charSheetMini, .charSheetWrapper {
        zoom: 0.86;
        /* IE */
        -moz-transform: scale(0.86);
        /* Firefox */
        -moz-transform-origin: 0 0;
        -o-transform: scale(0.86);
        /* Opera */
        -o-transform-origin: 0 0;
        -webkit-transform: scale(0.86);
        /* Safari And Chrome */
        -webkit-transform-origin: 0 0;
        transform: scale(0.86);
        /* Standard Property */
        transform-origin: 0 0;
        /* Standard Property */
    }
}

@media (min-width: 526px) {
    .charSheetMini, .charSheetWrapper {
        zoom: 0;
        /* IE */
        -moz-transform: scale(1);
        /* Firefox */
        -moz-transform-origin: 0 0;
        -o-transform: scale(1);
        /* Opera */
        -o-transform-origin: 0 0;
        -webkit-transform: scale(1);
        /* Safari And Chrome */
        -webkit-transform-origin: 0 0;
        transform: scale(1);
        /* Standard Property */
        transform-origin: 0 0;
        /* Standard Property */
    }
}