.SearchInputs {
    margin: auto;
    width: auto;
    margin-bottom: 10px;
    max-width: 720px;
}

.ServerSelect {
    min-width: 200px;
    max-width: 300px;
    z-index: 500;
    background-color: #111;
}

.ServerSelect > div {
    background-color: #111;
}

.CharacterInput {
    color: #fff;
    background-color: #111;
}

.SearchResults {
    text-align: center;
}

@media (min-width: 226px) {
    .CharacterInput {
        min-width: 220px !important;
    }
}

@media (min-width: 326px) {
    .CharacterInput {
        min-width: 120px !important;
    }
}

@media (min-width: 436px) {
    .CharacterInput {
        min-width: 220px !important;
    }
}

@media (min-width: 526px) {
    .CharacterInput {
        min-width: initial;
    }
}