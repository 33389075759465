.token {
    font-size: 24px;
    border: 2px dotted green;
    margin: 8px;
    padding: 8px;
}

.clipboard {
    margin-left: 20px;
}

.countdown {
    color: limegreen;
}