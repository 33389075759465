.ServerItemList {
    max-width: 720px;
    margin: auto;
}

.ServerItemListHeader {
    max-width: 720px;
    margin: auto;
    padding-bottom: 10px;
}

.ServerItemList a {
    color: #2FD5E7;
    text-decoration: none;
}

.ServerItemList a:active {
    color: #2FD5E7;
    text-decoration-color: #0056b3 !important;
    text-decoration: underline;
}

.ServerItemList a:hover {
    color: #2FD5E7;
    text-decoration-color: #0056b3 !important;
    text-decoration: underline;
}

.ServerItemList a:visited {
    color: #2FD5E7;
}

.ServerItemListItem {
    padding-left: 28px;
}

.serverName {
    font-size: large;
    background-color: #111;
}