/* examination panel base by RabidSquirrel */

/* top-level */

#examine {
    float: center;
}

.panel-container {
    color: #d6d8d8;
    width: 473px;
    display: flex;
    flex-direction: row;
    font-size: 12px;
}

.enbolden {
    color: white;
}

.color-00BFFF {
    color: #00bfff;
}

.color-6e5c8e {
    color: #6e5c8e;
}

.color-66FF99 {
    color: #66FF99;
}

.color-99fdff {
    color: #99fdff;
}

.color-eeef00 {
    color: #eeef00;
}

.color-ff0000 {
    color: #ff0000
}

.color-FFFFe0 {
    color: #ffffe0;
}

.color-bc4f4f {
    color: #bc4f4f;
}

.color-A020F0 {
    color: #a020f0;
}

.color-a970ff {
    color: #a970ff;
}

.color-ba3737 {
    color: #ba3737;
}

.color-D5D505 {
    color: #D5D505;
}

.color-D50505 {
    color: #D50505
}

.color-d6d8d8 {
    color: #d6d8d8;
}

.color-FFE28E {
    color: #FFE28E;
}

.color-FFFB3D {
    color: #FFFB3D;
}

/* left side-bar */

.panel-left-sidebar {
    width: 50px;
    position: relative;
    display: flex;
    flex-direction: column;
}

.panel-left-sidebar div {
    width: 50px;
}

.panel-left-top-content {
    background-image: url('../images/background-top-left.png');
    height: 37px;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
}

.panel-left-fangle {
    flex-grow: 10;
    z-index: 401;
}

.panel-left-top-image {
    height: 37px;
}

.panel-left-mid-content {
    height: 100%;
    position: relative;
    z-index: 1;
    flex-grow: 10;
    display: flex;
}

.panel-left-sidebar-image {
    min-height: 65px;
    height: 100%;
    flex-grow: 10;
    overflow: hidden;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
}

.panel-left-sidebar-bottom-image {
    align-self: flex-end;
    min-height: 53px;
    z-index: 201;
    position: relative;
}

.panel-left-sidebar-bottom-image {
    align-self: flex-end;
    min-height: 53px;
    z-index: 201;
    position: relative;
}

.panel-left-bottom-content {
    align-self: flex-end;
    min-height: 43px;
    z-index: 1;
    background-image: url('../images/background-bottom-left.png');
    background-repeat: no-repeat;
}

.panel-left-bottom-image {
    height: 43px;
    z-index: 201;
    position: relative;
}

/* body */

.panel-middle {
    width: 375px;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    flex-direction: column;
}

.panel-middle div {
    max-width: 375px;
}

.panel-middle-header {
    height: 37px;
    background-image: url('../images/background-top-middle.png');
    background-repeat: no-repeat;
    position: relative;
}

.panel-middle-header-image {
    height: 37px;
    position: relative;
}

.panel-middle-body-container {
    background-image: url('../images/background-mid.png');
    min-height: 76px;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
}

.panel-middle-body-content {
    min-width: 375px;
    position: relative;
    padding-bottom: 8px;
    display: flex;
    flex-direction: column;
    word-spacing: 1px;
    overflow-wrap: break-word;
}

.engolden {
    color: gold !important;
}

.panel-middle-body-content p {
    margin: 0;
    padding: 0;
}

.panel-main-row-one {
    display: flex;
    flex-direction: row;
}

.panel-main-row-two, .panel-main-row-three {
    display: flex;
    flex-direction: column;
}

.panel-main-row-three {
    padding-right: 10px;
}

.panel-main-icon {
    margin-left: 5px;
}

/* add middle custom classes here: */

.panel-name {
    padding-bottom: 10px;
    padding-top: 10px;
    text-align: center;
}

.panel-classes {
    display: flex;
    flex-direction: row;
    white-space: pre-wrap;
    flex-wrap: wrap;
}

/* data break */

.panel-middle-top-shadow {
    position: relative;
    display: flex;
}

.panel-middle-footer-data {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    min-height: 20px;
}

.panel-middle-coinage-value {
    width: 263px;
    margin-left: 14px;
    display: flex;
    flex-direction: row;
}

.panel-middle-coinage-value div {
    margin-left: 4px;
    padding-right: 14px;
}

.panel-middle-coinage-container {
    display: flex;
    flex-direction: row;
    margin-top: -1px;
}

.panel-middle-platinum-coinage,
.panel-middle-gold-coinage,
.panel-middle-silver-coinage,
.panel-middle-copper-coinage {
    margin-top: -1px;
}


.panel-middle-platinum-coinage::after {
    content: ' ';
    padding-right: 18px;
    background-image: url('../images/platinum-coinage.png');
    background-repeat: no-repeat;
    background-position-x: right;
    width: 18px;
    height: 21px;
    position: absolute;
    z-index: 402;
}

.panel-middle-gold-coinage::after {
    content: ' ';
    padding-right: 18px;
    background-image: url('../images/gold-coinage.png');
    background-repeat: no-repeat;
    background-position-x: right;
    width: 18px;
    height: 21px;
    position: absolute;
    z-index: 402;
}

.panel-middle-silver-coinage::after {
    content: ' ';
    padding-right: 18px;
    background-image: url('../images/silver-coinage.png');
    background-repeat: no-repeat;
    background-position-x: right;
    width: 18px;
    height: 21px;
    position: absolute;
    z-index: 402;
}

.panel-middle-copper-coinage::after {
    content: ' ';
    padding-right: 18px;
    background-image: url('../images/copper-coinage.png');
    background-repeat: no-repeat;
    background-position-x: right;
    width: 18px;
    height: 21px;
    position: absolute;
    z-index: 402;
}

.panel-middle-weight {
    width: 112px;
    text-align: center;
    margin-top: 1px;
}

.panel-middle-footer {
    background-image: url('../images/background-bottom-middle.png');
    background-size: 20px 20px;
    height: 43px;
    align-content: flex-end;
    background-repeat: no-repeat;
}

.panel-middle-footer-image {
    height: 43px;
}

.extra-info {
    border-radius: 20px;
    border: 2px solid rgb(255, 215, 80);
    background-image: url('../images/background-mid.png');
    margin-left: 40px;
    margin-right: 40px;
    padding: 8px;
    width: 473px;
    display: flex;
    flex-direction: column;
    font-size: 1em;
    min-height: 2px;
}

/* right side-bar */

.panel-right-sidebar {
    width: 48px;
    position: relative;
    display: flex;
    flex-direction: column;
}

.panel-right-sidebar div {
    width: 48px;
}

.panel-right-top-content {
    background-image: url('../images/background-top-right.png');
    height: 37px;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
}

.panel-right-top-image {
    height: 37px;
}

.panel-right-mid-content {
    height: 100%;
    position: relative;
    z-index: 1;
    flex-grow: 10;
    display: flex;
}

.panel-right-sidebar-image {
    flex-grow: 10;
    overflow: hidden;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
}

.panel-right-fangle {
    flex-grow: 10;
    z-index: 401;
}

.panel-right-bottom-image {
    align-self: flex-end;
    z-index: 201;
    position: relative;
}

.panel-right-sidebar-bottom-image {
    align-self: flex-end;
    min-height: 53px;
    z-index: 201;
    position: relative;
}

.panel-right-bottom-content {
    background-image: url('../images/background-bottom-right.png');
    background-repeat: no-repeat;
    align-self: flex-end;
    min-height: 43px;
    z-index: 1;
}

.panel-right-bottom-image {
    height: 43px;
    z-index: 201;
    position: relative;
}


/* this button acts like a link: */
.examination-button {
    background: none!important;
    border: none;
    padding: 0!important;
    color: rgb(255, 215, 80);
    text-decoration: none;
    cursor: pointer;
}

.examination-button:hover {
    background: none!important;
    border: none;
    padding: 0!important;
    color: #2FD5E7;
    text-decoration: underline;
    cursor: pointer;
}

.examination-button:active {
    background: none!important;
    border: none;
    padding: 0!important;
    color: #7C6E39;
    text-decoration: underline;
    cursor: pointer;
}

.panel-extra-adventure-pack {
    padding-left: 10px;
}

ul {
    padding-left: 10px;
}

li {
    padding-left: 10px;
    list-style-position: inside;
}

/* examinate scaling */

.charSheetMiniInfo,
.examinate {
    margin: auto;
}

@media (min-width: 226px) {
    .charSheetMini,
    .charSheetWrapper {
        zoom: 0.6;
        /* IE */
        -moz-transform: scale(0.60);
        /* Firefox */
        -moz-transform-origin: 0 0;
        -o-transform: scale(0.60);
        /* Opera */
        -o-transform-origin: 0 0;
        -webkit-transform: scale(0.60);
        /* Safari And Chrome */
        -webkit-transform-origin: 0 0;
        transform: scale(0.60);
        /* Standard Property */
        transform-origin: 0 0;
        /* Standard Property */
    }
}

@media (min-width: 326px) {
    .charSheetMini,
    .charSheetWrapper {
        zoom: 0.7;
        /* IE */
        -moz-transform: scale(0.70);
        /* Firefox */
        -moz-transform-origin: 0 0;
        -o-transform: scale(0.70);
        /* Opera */
        -o-transform-origin: 0 0;
        -webkit-transform: scale(0.70);
        /* Safari And Chrome */
        -webkit-transform-origin: 0 0;
        transform: scale(0.70);
        /* Standard Property */
        transform-origin: 0 0;
        /* Standard Property */
    }
}

@media (min-width: 436px) {
    .charSheetMini,
    .charSheetWrapper {
        zoom: 0.86;
        /* IE */
        -moz-transform: scale(0.86);
        /* Firefox */
        -moz-transform-origin: 0 0;
        -o-transform: scale(0.86);
        /* Opera */
        -o-transform-origin: 0 0;
        -webkit-transform: scale(0.86);
        /* Safari And Chrome */
        -webkit-transform-origin: 0 0;
        transform: scale(0.86);
        /* Standard Property */
        transform-origin: 0 0;
        /* Standard Property */
    }
}

@media (min-width: 526px) {
    .charSheetMini,
    .charSheetWrapper {
        zoom: 0;
        /* IE */
        -moz-transform: scale(1);
        /* Firefox */
        -moz-transform-origin: 0 0;
        -o-transform: scale(1);
        /* Opera */
        -o-transform-origin: 0 0;
        -webkit-transform: scale(1);
        /* Safari And Chrome */
        -webkit-transform-origin: 0 0;
        transform: scale(1);
        /* Standard Property */
        transform-origin: 0 0;
        /* Standard Property */
    }
}