body {
  background-color: #28417a;
  margin: 0;
  color: antiquewhite;
  font-family: Verdana, Geneva, Tahoma, sans-serif !important;
  font-size: 0.95rem;
  background: linear-gradient(to right, #0E1931, #125398);
  background: -moz-linear-gradient(to right, #0E1931, #125398);
  background: -ms-linear-gradient(to right, #0E1931, #125398);
  background: -webkit-linear-gradient(to right, #0E1931, #125398);
  background: -webkit-linear-gradient(to right, #0E1931, #125398);
  background: -o-linear-gradient(to right, #0E1931, #125398);
  background: linear-gradient(to right, #0E1931, #125398);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 100vh;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.App {
  text-align: center;
}

.voklogo {
  padding-right: 5px;
}

.voklogoText {
  padding-left: 5px;
}

.headerRow {
  margin: auto;
  min-height: 100px;
  margin-bottom: 10px;
}

nav ul {
  padding: 5px;
  margin: 0;
}

nav li {
  padding-left: 13px;
  display: inline;
}

small {
  color: antiquewhite;
}

a {
  color: rgb(255, 215, 80) !important; 
  text-decoration: none;
}

a:hover {
  color: #2FD5E7 !important; 
  text-decoration: underline;
}

a:active {
  color: #7C6E39 !important; 
  text-decoration: underline;
}

nav a {
  color: rgb(255, 215, 80) !important; 
  text-decoration: none;
}

nav a:active {
  color: #2FD5E7;
  text-decoration: underline;
}

nav a:hover {
  color: #2FD5E7;
  text-decoration: underline;
}

nav a:visited {
  color: #2FD5E7;
}

.profileHeader {
  position: absolute;
  right: 0;
}

.profileThumbnail {
  height: 30px;
  width: auto;
}

/* Small devices (landscape phones, 576px and up) */

@media (min-width: 1px) {
  #profilename {
    visibility: hidden;
  }
}

@media (min-width: 276px) {
  #profilename {
    visibility: hidden;
  }
}

@media (min-width: 476px) {
  #profilename {
    visibility: hidden;
  }
  .headerRow {
    max-width: 483px;
  }
}

@media (min-width: 576px) {
  #profilename {
    visibility: hidden;
  }
}

/* Medium devices (tablets, 768px and up) */

@media (min-width: 768px) {
  #profilename {
    visibility: visible;
  }
  
  .headerRow {
    max-width: 483px;
  }
}

/* Large devices (desktops, 992px and up) */

@media (min-width: 992px) {
  .headerRow {
    max-width: 100%;
  }
}

/* Extra large devices (large desktops, 1200px and up) */

@media (min-width: 1200px) {}

.tooltip-inner {
  text-align: left;
}

.tooltip-inner-sub {
  margin-left: 20px;
  font-size: 0.9em;
}

